<template>
    <div class="zrgj">
        <!-- 系统预警配置 -->
        <div class="box" style="padding-right: 12px">
            <!-- 列表 -->
            <div style="position: relative">
                <el-table :cell-style="{ padding: '3px 0px' }" :header-row-style="{ height: '30px' }" :data="tableData"
                    row-key="id" lazy size="mini" stripe class="tableClass" tooltip-effect="dark" style="width: 100%"
                    :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }" @sort-change="handleSortChange">
                    <el-table-column prop="serviceType"  :label="$t('System.systemWarningPage.ServiceType')" width="120" :show-overflow-tooltip="true">
                        <template v-slot="{ row }">
                            <template v-if="row.serviceType == 'gateway'">
                                {{ $t("System.systemWarningPage.GatewayService") }}
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column prop="description" :label="$t('System.systemWarningPage.WarningConditions')" width="220" :show-overflow-tooltip="true">
                        <template v-slot="{ row }">
                            {{ row.description }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="warningType" :label="$t('System.systemWarningPage.WarningType')" width="200" :show-overflow-tooltip="true">
                        <template v-slot="{ row }">
                            <template v-if="row.warningType == '1'">
                                {{ $t("System.systemWarningPage.CPU") }}
                            </template>
                            <template v-if="row.warningType == '2'">
                                {{ $t("System.systemWarningPage.Memory") }}
                            </template>
                            <template v-if="row.warningType == '3'">
                                {{ $t("System.systemWarningPage.HardDisk") }}
                            </template>
                            <template v-if="row.warningType == '4'">
                                {{ $t("System.systemWarningPage.ServiceException") }}
                            </template>
                            <template v-if="row.warningType == '5'">
                                {{ $t("System.systemWarningPage.ServiceException") }}
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column prop="enable" :label="$t('System.systemWarningPage.DeactivateEnable')" width="200" :show-overflow-tooltip="true">
                        <template v-slot="{ row }">
                            <el-switch v-model="row.enable" active-value="1" inactive-value="0" @change="changeSwitch(row)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('public.Controls')">
                        <template v-slot="{ row }">
                            <template v-if="row.warningType == '4' || row.warningType == '5'">
                            </template>
                            <span class="action_icon" v-else>
                                <img @click="edit(row)" :title="$t('public.Editor')" src="@/assets/icon_write.png"
                                    style="width: 15px; height: 15px; cursor: pointer" />
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <pagination :page="currentPage4" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]"
                    v-on:handleChildGetList="handleParentGetList"></pagination>
            </div>
        </div>
        <Eldialog class="dialog" @handleClose="handleClose" :title="$t('System.systemWarningPage.AlertConditionConfig')" :flagbtn="flagbtn" :btnTitle="$t('public.Save')"
            :determineBtn="determineBtn" :cancelBtn="cancelBtn" @determine="saveCheck" :dialogVisible="flag" v-if="flag">
            <el-form size="small" :model="addArray" ref="addArray" label-width="170px" style="width:400px;" :rules="rules">
                <el-form-item :label="$t('System.systemWarningPage.WarningConditions')+'：'" prop="threshold">
                    {{ type }}{{ $t("System.systemWarningPage.UsageRate") }}<el-input v-model.number="addArray.threshold" maxlength="3" @input="handleEdit"
                        oninput="this.value = this.value && parseInt(this.value);this.value < 0 || this.value>100 && (this.value = 100)"
                        size="mini" style="width:50px;margin-left:4px;height:20px;"></el-input>&nbsp;%
                </el-form-item>
            </el-form>
            <div class="footertrans" v-if="flagbtn" slot="footer">
                <el-button v-if="determineBtn" size="mini" type="primary" class="btn-determine" @click="saveCheck">{{ $t("public.Save") }}
                </el-button>
                <el-button v-if="cancelBtn" size="mini" class="btn-cancel" @click="handleClose">{{ $t("public.Cancel") }}</el-button>
            </div>
        </Eldialog>
        <!-- 保存成功的弹框 -->
        <dialog-info :dialogInfo="Successdialog" @handleClose="handleClose" @determine="determine" :titleInfo="seccess"
            :width="'240px'" />
    </div>
</template>
<script>
import pagination from '@/components/pagination/page';
import naviGation from '@/components/hearder/index';
import dialogInfo from '@/components/promptMessage/index';
import Eldialog from '@/components/elDialog/index.vue';
export default {
    components: { pagination, naviGation, dialogInfo, Eldialog },

    data() {
        return {
            id: '',
            type: "",
            name: '',
            memo: '',
            total: 0,
            time: null,
            seccess: '',
            Sencond: 5, //设置初始倒计时
            addArrayId: '', //新增点击传过去组织树的id
            pageSize: 10, //每页显示条数
            currentPage4: 1, //当前页
            flag: false,
            flagbtn: true,
            cancelBtn: true,
            isDisabled: false,
            determineBtn: true,
            Successdialog: false, //控制弹出
            // addArray: null,
            delarr: [], //删除
            content: [], // 信息类型   转字符串
            tableData: [],
            addArray: {
                threshold: "80"
            },
            page: {
                orderColume: 'lastUpdateTime', //排序字段
                orderRule: 'DESC' //排序规则ASC、DESC
            },
            rules: {
                threshold: [
                    { required: true, message: this.$t('System.systemWarningPage.thresholdVoid'), trigger: 'blur' },
                ],
            }
        }
    },
    watch: {},
    created() { },

    mounted() {
        this.getDataList()
    },
    methods: {
        handleEdit(e) {
            let value = e.replace(/[^\d]/g, ""); // 只能输入数字
            value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
            value = value.replace(/(\d{3})\d*/, '$1') // 最多保留3位整数
            this.addArray.threshold = value
        },
        //监听排序
        handleSortChange(column) {
            if (column.order === 'ascending') {
                this.page.orderRule = 'ASC'
            } else if (column.order === 'descending') {
                this.page.orderRule = 'DESC'
            }
            this.page.orderColume = column.prop;
            this.getDataList();
        },
        // 列表
        async getDataList() {
            var param = {
                serviceType: "gateway"
            }
            // 1为启用    0为禁用
            const res = await this.$axios.post('/httpServe/alarmConfig/getDataInfo', param, true)
            // console.log(res.data.content, '列表数据')
            this.tableData = res.data.content;
            this.total = res.data.total;
        },
        // 停用启用
        async changeSwitch(row) {
            const data = {
                id: row.id,
                enable: row.enable,
            };
            console.log(data);
            const res = await this.$axios.post(
                "/httpServe/alarmConfig/enable",
                data,
                true
            );
            this.getDataList();
        },
        // 编辑回显
        edit(val) {
            this.flag = true;
            // console.log(val, "编辑回显");
            if (val.warningType == 1) {
                this.type = this.$t('System.systemWarningPage.CPU');
            } else if (val.warningType == 2) {
                this.type = this.$t('System.systemWarningPage.Memory');
            } else if (val.warningType == 3) {
                this.type = this.$t('System.systemWarningPage.HardDisk');
            } else if (val.warningType == 4) {
                this.type = this.$t('System.systemWarningPage.ManagementServiceException');
            } else if (val.warningType == 5) {
                this.type = this.$t('System.systemWarningPage.GatewayServiceException');
            }
            this.addArray = val;
        },
        saveCheck() {
            this.$refs.addArray.validate((valid) => {
                if (valid) {
                    this.editSave()
                } else {
                    this.flag = true; //弹框关闭
                    console.log('error submit!!')
                    return false
                }
            })

        },
        // 编辑保存
        async editSave() {
            var parma = {
                id: this.addArray.id,
                threshold: this.addArray.threshold,
            }
            const res = await this.$axios.post(
                '/httpServe/alarmConfig/update',
                parma,
                true
            )
            if (res.code == 200) {
                this.$message({
                    message: this.$t('public.SuccessfullySaved'),
                    type: 'success',
                    offset: 100
                })
                this.getDataList()
                this.flag = false
            } else {
                this.$message({
                    message: res.message,
                    type: 'error',
                    offset: 100
                })
            }
        },
        handleClose() {
            this.Successdialog = false //提示消息  关闭
            this.flag = false;
        },
        determine() {
            this.Successdialog = false //提示消息  关闭
        }, // 响应分页组件查询事件
        handleParentGetList(page, limit) {
            this.currentPage4 = page
            this.pageSize = limit
            // 调用查询方法
            this.getDataList()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.currentPage4 = val
            this.getDataList()
        },
        //全选遮罩层
        maskLayer() {
            this.isCheckShow = true
        },
        // 鼠标移出弹框
        leave() {
            this.isCheckShow = false
        },
        checkChange(selection, row) {
            selection.forEach((item) => {
                this.delarr.push(item.id)
            })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        //当前页全选
        curSelection() {
            this.$refs.tableData.toggleAllSelection()
        },
        //取消全选
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    row.forEach((item) => {
                        item.checked = true
                    })
                    this.$refs.tableData.toggleRowSelection(row)
                })
            } else {
                this.$refs.tableData.clearSelection()
            }
            this.isCheckShow = true
        }
    }
}
</script>

<style lang="scss" scoped>
* {
    font-family: Microsoft YaHei;
    font-size: 12px;
}

.breadtitle {
    margin-left: 10px;
    border-bottom: 1px solid #cdd1dd;
    height: 40px;
    line-height: 40px;
    color: #6d7a82;
    font-size: 16px;
}

.zrgj {
    width: 100%;
    height: 100%;
}

.el-input1 {
    width: 200px;
    margin-left: 1px;
}

.btn {
    margin-left: 1px;
    background: #4787de;
    margin-right: 1px;
}

.searchbtn {
    margin-left: 4px;
}

.center {
    margin: 10px 0;
    justify-content: flex-start;
}

.save {
    margin: 4px 0 102px 200px;
}

.el-form {
    margin-top: 25px;
}

.el-icon-edit,
#el-icon-close {
    border: 1px solid;
    padding: 3px;
    border-radius: 5px;
    margin-right: 5px;
    color: #4787de;
    font-weight: bold;
}

.box {
    margin-left: 10px;
    margin-top: 10px;
}

.el-input {
    width: 50%;
}

#text {
    resize: none;
}

span>>>.el-input>input {
    height: 24px;
}

.el-form-item__content {
    width: 78%;
    display: flex;
    margin: 0 !important;
}

// #success{
//   // height: 100px;
// }

.el-button+.el-button {
    margin-left: 0px;
}

.el-checkbox {
    margin-right: 10px;
}

.el-radio,
.el-radio__input {
    margin-left: 10px;
}

.el-checkbox,
.el-checkbox__input {
    margin-left: 3px;
}

.el-textarea__inner {
    resize: none;
}

.el-select .el-input {
    width: 120px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.el-checkbox:last-of-type {
    margin-left: 12px;
}

.el-form {
    width: 848px;
}

// .el-form-item {
//   margin-bottom: 5px;
// }
.el-form[data-v-42c2d864] {
    margin-top: 10px;
}

.el-form-item__content {
    width: 662px;
    display: flex;
    margin: 0 !important;
}

.el-dialog .el-dialog__body {
    padding: 5px 12px 24px 20px !important;
    //height: 100px;
    overflow: auto;
    margin-right: 12px;
}

.el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    height: 14px;
    line-height: 20px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}

.el-form-item__content {
    line-height: 36px;
    position: relative;
    font-size: 14px;
}

.save[data-v-42c2d864] {
    margin: 21px 0 102px 200px !important;
    margin-bottom: 110px;
}
</style>
<style lang="scss">
.save[data-v-42c2d864] {
    margin: 21px 0 102px 200px !important;
    margin-bottom: 110px;
}

::v-deep .el-table__row>td {
    border: none;
}

::v-deep .el-table::before {
    height: 0px;
}

::v-deep .el-table th.is-leaf {
    border: none;
}

.footertrans {
    margin: 20px 0 0 480px;
}

.btn-determine {
    margin-right: 19px;
}
</style>